<template>
  <v-row class="not-found-container" no-gutters>
    <v-col cols="12">
      <v-icon class="mobile-icon">{{icon}}</v-icon>
      <div class="not-found-title">
        {{title}}.
      </div>
      <div class="not-found-action-container" v-if="showNotFoundAction">
        <v-btn class="text-none not-found-action" dark large outlined @click="go">
          <v-icon left>{{buttonIcon}}</v-icon>{{buttonText}}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import navigationMixin from '@/mixins/navigation'

export default {
  mixins: [navigationMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'history'
    },
    buttonIcon: {
      type: String,
      default: 'add'
    },
    buttonText: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: true
    }
  },
  computed: {
    showNotFoundAction () {
      return !!this.buttonText && !!this.action && !!this.action.name
    }
  },
  methods: {
    go () {
      this.goToRouteByName(this.action.name, this.action.params)
    }
  }
}
</script>
<style scoped>
.not-found-container{
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -6.25rem;
}
.mobile-icon {
  font-size: 8.125rem;
  color: grey;
}
.not-found-title {
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 1.25rem;
}
.not-found-action-container {
  padding-bottom: 0.25rem;
}
.not-found-action {
  color: var(--app-button-bg-color) !important;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .mobile-icon {
    font-size: 6.25rem;
  }
  .not-found-title {
    font-size: 1rem !important;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }
  .not-found-action-container {
    padding-bottom: 0;
  }
}
</style>
