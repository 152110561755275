<template>
  <v-row class="loader-container" no-gutters>
    <v-col xl="11" lg="11" md="11" sm="12" cols="12">
      <v-skeleton-loader v-for="i in 10" type="list-item-avatar-three-line" :key="i + '_item'"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ListLoader'
}
</script>
<style scoped>
.loader-container {
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
/* 959px */
@media only screen and (max-width: 59.938rem) {
  .loader-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
