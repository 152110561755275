<template>
  <v-card class="card-item-container" tile flat>
    <v-row no-gutters :class="{ 'app-border-bottom': printBorder }">
      <v-col class="avatar-container" xl="2" lg="2" md="2" sm="2" cols="3">
        <slot name="avatar">
          <v-avatar class="avatar">{{ firstLetter }}</v-avatar>
        </slot>
      </v-col>
      <v-col xl="9" lg="9" md="9" sm="9" cols="7" class="item-container">
        <slot name="content"></slot>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { getFirstLetter } from '@/lists/lists'

export default {
  props: {
    title: {
      type: String
    },
    printBorder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      firstLetter: null
    }
  },
  mounted () {
    if (this.title) {
      this.firstLetter = getFirstLetter(this.title)
    }
  }
}
</script>
<style scoped>
.card-item-container {
  cursor: pointer;
  padding-bottom: 0.25rem;
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  background-color: var(--app-avatar-bg-color);
  color: var(--app-avatar-color);
  height: 3rem !important;
  min-width: 3rem !important;
  width: 3rem !important;
  text-transform: uppercase;
}
.item-container {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}
/* 600px */
@media only screen and (max-width: 37.5) {
  .item-container {
    padding-top: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
</style>
