// router-name: config
const NOT_FOUND_CONFIG = {
  brands: newNotFoundConfig('No se encontraron marcas', 'history', 'add', 'Crear marca', 'create-brand'),
  'update-brand': newNotFoundConfig('Marca no encontrada', 'history', 'list', 'Listar marcas', 'brands'),
  products: newNotFoundConfig('No se encontraron productos', 'history', 'add', 'Crear producto', 'create-product'),
  'delivery-zones': newNotFoundConfig('No se encontraron zonas de envió', 'history', 'add', 'Crear zona de envió', 'create-delivery-zone'),
  orders: newNotFoundConfig('No se encontraron ventas', 'history', '', '', ''),
  collections: newNotFoundConfig('No se encontraron solicitudes de recogida', 'local_shipping', 'add', 'Crear solicitud de recogida', 'create-collection-request')
}

function newNotFoundConfig (title, icon, buttonIcon, buttonText, routeName, routeParams = {}) {
  return {
    title,
    icon,
    buttonIcon,
    buttonText,
    action: {
      name: routeName,
      params: routeParams
    }
  }
}

export function getNotFoundConfig (name) {
  return NOT_FOUND_CONFIG[name] ? NOT_FOUND_CONFIG[name] : null
}
