export default {
  methods: {
    goTo (path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    goToRouteByName (name, params = {}, query = {}) {
      if (this.$route.name === name && JSON.stringify(this.$route.params) === JSON.stringify(params)) {
        return
      }

      this.$router.push({ name: name, params: params, query: query })
    },
    goToNewTab (name, params = {}) {
      const route = this.$router.resolve({ name: name, params: params })
      window.open(route.href, '_blank')
    }
  }
}
