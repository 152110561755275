<template>
  <v-row class="list-container" no-gutters>
    <v-col xl="8" lg="9" md="11" sm="12" cols="12">

      <v-btn class="create-btn" fab @click="goToRouteByName(listConfig.createRouteName)" v-if="showCreate">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-card :class="{ 'card-wrapper': $vuetify.breakpoint.smAndDown, 'card-wrapper-not-found': showNofFound }" elevation="2" tile>
        <div class="app-border-bottom" v-if="allowSearch">
          <v-text-field class="search-input"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar"
            clearable
            hide-details
            :height="60"
            @keydown.enter="query" />
        </div>

        <list-loader v-if="showSkeletonLoader" />
        <data-not-found class="data-not-found" v-else-if="showNofFound" v-bind="notFoundConfig" />
        <div v-else-if="showList">
          <div class="list-title" v-if="listConfig">{{ listConfig.title }}</div>

          <slot name="items"></slot>

          <v-card class="progress-container app-border-top" tile flat v-if="list.next">
            <v-progress-circular class="progress" indeterminate size="24" width="3" v-if="loading"></v-progress-circular>
            <v-btn text class="text-none load-more" small @click="load" v-else>Cargar Mas</v-btn>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ListLoader from '@/components/Loader/ListLoader'
import DataNotFound from '@/components/Shared/DataNotFound'
import { getListConfig, buildListURL } from '@/lists/lists'
import { getNotFoundConfig } from '@/shared/notFoundConfig'

export default {
  mixins: [navigationMixin],
  components: {
    ListLoader,
    DataNotFound
  },
  props: {
    list: {
      required: true
    },
    allowSearch: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      listConfig: null,
      notFoundConfig: null,
      search: null
    }
  },
  computed: {
    ...mapState('shared', ['loading']),
    showSkeletonLoader () {
      return this.loading && !this.list
    },
    showList () {
      return !!this.list
    },
    showNofFound () {
      return !this.list && !!this.notFoundConfig
    },
    showCreate () {
      return !!this.list && !!this.listConfig && !!this.listConfig.createRouteName
    }
  },
  methods: {
    load () {
      this.$emit('load', buildListURL(this.list.next, this.search))
    },
    query () {
      this.$emit('query', this.search)
    }
  },
  mounted () {
    this.listConfig = getListConfig(this.$route.name)
    this.notFoundConfig = getNotFoundConfig(this.$route.name)
  }
}
</script>
<style scoped>
.list-container {
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-wrapper {
  min-height: 100%;
}
.card-wrapper-not-found {
  min-height: 38.75rem;
}
.list-title {
  text-align: left;
  padding: 0.875rem 1.5rem 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--app-menu-title-color);
}
.v-text-field {
  padding-top: 0;
}
.search-input {
  margin: 0;
  padding: 0;
  outline: none;
  overflow-x: scroll;
  overflow-y: hidden;
}
.search-input >>> .v-input__prepend-inner {
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 0 !important;
  margin-left: 1rem;
  margin-right: 0.25rem;
}
.search-input >>> .v-input__append-inner {
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 0 !important;
  margin-right: 1rem;
  margin-left: 0.25rem;
}
.progress-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.progress {
  color: var(--app-progress-circular-color);
}
.load-more {
  color: var(--app-button-bg-color);
}
.create-btn {
  position: fixed;
  z-index: 1;
  right: 1.25rem;
  bottom: 1.25rem;
  background-color: var(--app-floated-button-bg-color) !important;
}
.create-btn >>> .v-btn__content {
  color: #FFFFFF;
}
.data-not-found {
  min-height: 38.75rem;
}
/* 959px */
@media only screen and (max-width: 59.938rem) {
  .list-container {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100%;
  }
  .card-wrapper-not-found {
    min-height: 100%;
  }
  .data-not-found {
    min-height: 100vh;
  }
}
</style>
