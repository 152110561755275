<template>
  <list-container :list="collections" @load="loadMoreCollections" @query="queryCollections">
    <template v-slot:items>
      <div v-if="drawItems">
        <div v-for="(collection, index) in collections.collections" :key="collection.collection_id" @click="view(collection.collection_id)">
          <list-item-container title="Recogida" :printBorder="index < count">
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                {{ collection.start_time }} - {{ collection.final_time }}
              </div>
              <div class="list-item-subtitle">
                <div>Codigo: {{collection.collection_code}}</div>
              </div>
              <div class="detail-content">
                <div>{{collection.orders.length}} ordenes</div>
              </div>
              <div class="list-item-subtitle creation-date-item">
                Creado el {{ dateFormat(collection.creation_date, 'D MMM YYYY') }}
              </div>
            </template>
          </list-item-container>
        </div>
      </div>
    </template>
  </list-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ListContainer from '@/components/List/ListContainer'
import ListItemContainer from '@/components/List/ListItemContainer'
import { dateFormat } from '@/shared/format'

export default {
  mixins: [navigationMixin],
  components: {
    ListContainer,
    ListItemContainer
  },
  computed: {
    ...mapState('collection', ['collections']),
    drawItems () {
      return this.collections && this.collections.collections
    },
    count () {
      return this.collections.collections.length - 1
    }
  },
  methods: {
    dateFormat,
    ...mapActions('collection', ['getCollections', 'getMoreCollections']),
    view (id) {
      this.goToRouteByName('view-collection-request', { id })
    },
    loadMoreCollections (url) {
      this.getMoreCollections(url)
    },
    queryCollections (search) {
      this.getCollections(search)
    }
  },
  mounted () {
    this.getCollections()
  }
}
</script>
<style scoped>
.detail-content {
  width: 100%;
  text-align: left;
  color: #9E9E9E;
  font-size: 0.938rem;
  display: inline-block;
}
.creation-date-item {
  margin-bottom: 0.625rem;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .list-item-title {
    padding-top: 0.125rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
  }
}
</style>
